<template>
<g>
  <svg:style>
    .cls-10, .cls-11, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
      stroke: #aeaeae;
      stroke-miterlimit: 10;
      stroke-width: 0.25px;
    }

    .cls-4 {
      fill: url(#inox-gradient);
    }

    .cls-5,
    .cls-7,
    .cls-9,
    .cls-11 {
      fill: url(#glass-pattern);
    }

    .cls-6 {
      fill: url(#inox-gradient-2);
    }

    .cls-8 {
      fill: url(#inox-gradient-3);
    }

    .cls-10 {
      fill: url(#inox-gradient-4);
    }
  </svg:style>
  <g id="h03">
    <linearGradient id="inox-gradient"
                    :x1="doorLeftWidth1 + 22.18"
                    :y1="doorTopHeight1 + 171.63"
                    :x2="doorLeftWidth1 + 119.46"
                    :y2="doorTopHeight1 + 171.63"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-2"
                    data-name="inox"
                    :x1="doorLeftWidth1 + 22.18"
                    :y1="doorTopHeight1 + 214.05"
                    :x2="doorLeftWidth1 + 119.46"
                    :y2="doorTopHeight1 + 214.05"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-3"
                    data-name="inox"
                    :x1="doorLeftWidth1 + 22.18"
                    :y1="doorTopHeight1 + 129.21"
                    :x2="doorLeftWidth1 + 119.46"
                    :y2="doorTopHeight1 + 129.21"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-4"
                    data-name="inox"
                    :x1="doorLeftWidth1 + 22.18"
                    :y1="doorTopHeight1 + 86.79"
                    :x2="doorLeftWidth1 + 119.46"
                    :y2="doorTopHeight1 + 86.79"
                    xlink:href="#handle-gradient"/>

    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>

    <rect id="inox"
          v-if="showInox"
          data-name="inox"
          class="cls-4"
          :x="inoxX"
          :y="`${doorTopHeight1 + 161.02}`"
          :width="inoxW"
          height="21.21"/>

    <rect filter="url(#inset-shadow)"
          id="glass"
          class="cls-5"
          :x="`${doorLeftWidth1 + 43.25}`"
          :y="`${doorTopHeight1 + 165.27}`"
          width="55.14"
          height="12.73"/>

    <rect id="inox-2"
          v-if="showInox"
          data-name="inox"
          class="cls-6"
          :x="inoxX"
          :y="`${doorTopHeight1 + 203.44}`"
          :width="inoxW"
          height="21.21"/>

    <rect filter="url(#inset-shadow)"
          id="glass-2"
          data-name="glass"
          class="cls-7"
          :x="`${doorLeftWidth1 + 43.25}`"
          :y="`${doorTopHeight1 + 207.68}`"
          width="55.14"
          height="12.73"/>

    <rect id="inox-3"
          v-if="showInox"
          data-name="inox"
          class="cls-8"
          :x="inoxX"
          :y="`${doorTopHeight1 + 118.61}`"
          :width="inoxW"
          height="21.21"/>

    <rect filter="url(#inset-shadow)"
          id="glass-3"
          data-name="glass"
          class="cls-9"
          :x="`${doorLeftWidth1 + 43.25}`"
          :y="`${doorTopHeight1 + 122.85}`"
          width="55.14"
          height="12.73"/>

    <rect id="inox-4"
          v-if="showInox"
          data-name="inox"
          class="cls-10"
          :x="inoxX"
          :y="`${doorTopHeight1 + 76.19}`"
          :width="inoxW"
          height="21.21"/>

    <rect filter="url(#inset-shadow)"
          id="glass-4"
          data-name="glass"
          class="cls-11"
          :x="`${doorLeftWidth1 + 43.25}`"
          :y="`${doorTopHeight1 + 80.43}`"
          width="55.14"
          height="12.73"/>
  </g>
</g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
  ],
  data() {
    return {
      inoxOffset: 22.18,
      leafOffset: 6.4
    }
  },
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 150
    },
    inoxW() {
      return this.showBg ?
        this.doorWidth - 2 * this.inoxOffset :
        this.doorWidth - 2 * this.leafOffset
    },
    inoxX() {
      return this.showBg ?
        this.doorLeftWidth + this.inoxOffset :
        this.doorLeftWidth + this.leafOffset
    }
  },
}
</script>
